<template>
  <div>
    <div class="__logo">
    <img :src="outerImg" alt="">
    <img class="inner-img" :src="innerImg" alt="">
    <h5 class="__title">{{ title }}</h5>
  </div>
  </div>
</template>
<script>
import outerImg from '../assets/img/timecard/green-circle.png';
import innerImg from '../assets/img/timecard/application.png';

export default {
  props: ['title'],
  data () {
    return {
      outerImg: outerImg,
      innerImg: innerImg,
    }
  }
}
</script>
<style lang="scss">
.__logo {
  position: relative;
  height: 81px;
  width: 81px;

  .inner-img {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    margin-top: 18px;
    margin-left: 18px;
  }
}

.__title {
  color: #049C4C;
  text-align: center;
  width: 100%;
  margin-top: 15px;
}
</style>