<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-5 mt-5">
        <div class="col-sm-12 text-center">
          <div class="date-navigation d-flex justify-content-center">
            <button class="btn btn-primary btn-flat" :disabled="isButtonDisabled" @click="navigate(-1)">&#60;</button>
            <span class="date-text">{{ dateDisplayed }}</span>
            <button class="btn btn-primary btn-flat" @click="navigate(1)">&#62;</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>名前</th>
                <th v-for="(dayLabel, index) in daysInMonth" :key="index">
                  {{ dayLabel }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entry) in sortedData" :key="entry.id">
                <td>{{ entry.staff }}</td>
                <td v-for="(day) in daysInMonth" :key="day">
                  {{ getDataForDay(entry.data, day) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-4">
        <button class="btn btn-primary btn-block" :disabled="isCSVDisabled" @click="csvDownload">CSVダウンロード</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      data: [],
      currentDate: new Date(),
      dateDisplayed : null,
      daysInMonth: [],
      company_plan: 1,
      navigatedDate: this.$moment(),
    }
  },
  computed: {
    sortedData() {
      const staffDataMap = new Map();

      // Iterate through your data and group timecard and paid_leave data by staff
      this.data.forEach(entry => {
        const staffName = entry.name;

        if (!staffDataMap.has(staffName)) {
          staffDataMap.set(staffName, {
            staff: staffName,
            regular_working_hours_start: entry.regular_working_hours_start,
            regular_working_hours_stop: entry.regular_working_hours_stop,
            working_hours: entry.regular_working_hours,
            data: [],
          });
        }

        if (entry.timecard && entry.timecard.length > 0) {
          entry.timecard.forEach(timecardEntry => {
            staffDataMap.get(staffName).data.push({
              type: 'timecard',
              ...timecardEntry,
            });
          });
        }

        if (entry.paid_leaves && entry.paid_leaves.length > 0) {
          entry.paid_leaves.forEach(paidLeaveEntry => {
            staffDataMap.get(staffName).data.push({
              type: 'paid_leave',
              ...paidLeaveEntry,
            });
          });
        }
      });

      staffDataMap.forEach((staffData) => {
        staffData.data.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
      });

      return Array.from(staffDataMap.values());
    },
    isButtonDisabled() {
      if (this.company_plan === 0) {
        if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-DD')) {
          return true;
        } else {
          if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-05')) {
            return true;
          }
          return false;
        }
      } else {
        return false;
      }
    },
    isCSVDisabled() {
      if (this.company_plan === 0) {
        if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-DD')) {
          return true;
        } else {
          // if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-05')) {
          //   return true;
          // }
          return false;
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.updateDateDisplayed();
    this.updateDaysInMonth();
    this.getWorkCalendar(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1)+'-01');
  },
  methods: {
    navigate (direction) {
      this.currentDate.setMonth(this.currentDate.getMonth() + direction);
      this.navigatedDate = this.navigatedDate.clone().add(direction, 'months');
      this.updateDateDisplayed();
      this.getWorkCalendar(
        this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1)+'-01'
      );
    this.updateDaysInMonth();
    },
    updateDateDisplayed () {
      this.dateDisplayed =
        this.currentDate.getFullYear() + '年' + (this.currentDate.getMonth() + 1) + '月';
    },
    getDayName (dayOfWeek) {
      const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
      return daysOfWeek[dayOfWeek];
    },
    updateDaysInMonth () {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth() + 1;
      const daysCount = new Date(year, month, 0).getDate();
      const dayLabels = [];

      for (let day = 1; day <= daysCount; day++) {
        const dayOfWeek = new Date(year, month - 1, day).getDay();
        const dayLabel = `${day}(${this.getDayName(dayOfWeek)})`;
        dayLabels.push(dayLabel);
      }
      this.daysInMonth = dayLabels;
    },
    getDataForDay(timecard, day) {
      const dayNumber = parseInt(day.match(/\d+/)[0]);
      const dataForDay = timecard.find(item => {
        const itemDay = new Date(item.date).getDate();
        return itemDay === dayNumber;
      });
      if (dataForDay) {
        if (dataForDay.type === 'timecard') {
          const time_in = dataForDay.time_start;
          const time_out = dataForDay.time_stop ? dataForDay.time_stop : '未';
          return time_in + ' - ' + time_out;
        } 
        else {
          return '有給休暇';
        }
      } else {
        return '';
      }
      
    },
    getWorkCalendar (date) {
      const shop_id = this.$store.getters.shop;
      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/workCalendar/' + shop_id + '/' + date)
      .then(response => {
        this.data = response.data.data.staff;
        this.company_plan = response.data.data.company_plan;
      })
      .catch(error => {
        console.log(error);
      });
    },
    csvDownload() {
      const shop_id = this.$store.getters.shop;
      const date = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1) + '-01';

      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/csvDownload/' + shop_id + '/' + date, {
        responseType: 'blob'
      })
      .then(response => {
        // Handle the response as a ZIP file
        const blob = new Blob([response.data], { type: 'application/zip' });

        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'staff-csv-files.zip'; // Change the filename to reflect ZIP
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.log(error);
      });
    },
    viewDetails () {
      this.$router.push({name:'CompanyShopDetailsPage'});
    },
    handleRowClick () {
      // const lastTD = event.target.parentNode.lastElementChild;
      // const lastTDValue = lastTD.innerText;
        $("#modal").modal();
    },
  },
};
</script>

<style scoped>
  .date-navigation {
    display: flex;
    align-items: center;
  }

  .arrow-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .date-text {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
  }
</style>