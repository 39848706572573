<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">名前<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model="formData.name">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">メールアドレス<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model="formData.email">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">パスワード<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model="formData.password">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">所属店舗</span>
        <div class="col-sm-8">
          <select class="select2" id="shops" multiple="multiple" data-placeholder="所属店舗を選択" style="width: 100%;">
            <option v-for="(shop) in shops" :key="shop.id" :value="shop.id">{{ shop.shop_name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">勤務時間<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <div class="d-flex justify-content-between">
            <input type="time" v-model="formData.regular_working_hours_start" class="form-control" />
            <span class="my-auto mx-2">-</span>
            <input type="time" v-model="formData.regular_working_hours_stop" class="form-control" />
          </div>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">休憩<span class="required ml-3">*</span></span>
        <div class="col-sm-2">
          <input type="number" v-model="formData.break_time" class="form-control" placeholder="時間単位で入力"/>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">時給<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="number" class="form-control" v-model="formData.hourly_wage">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">有給残日数</span>
        <div class="col-sm-3">
          <input type="number" class="form-control" v-model="formData.remaining_paid_leave_days">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">年齢<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="number" class="form-control" v-model="formData.age">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">性別<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <select class="form-control" v-model="formData.gender">
            <option value="男">男</option>
            <option value="女">女</option>
            <!-- 特定しない -->
            <option value="特定しない">特定しない</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input class="form-control" v-model="formData.phone_number" placeholder="ハイフンなし整数で⼊⼒してください" @keypress="validateNumber">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">郵便番号<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input class="form-control" v-model="formData.postal_code" placeholder="ハイフンなし整数で⼊⼒してください" @keypress="validateNumber">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <textarea class="form-control" cols="30" rows="3" v-model="formData.address"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">入社日<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="date" class="form-control" v-model="formData.date_of_joining">
        </div>
        <label class="col-sm-2 col-form-label font-weight-bold">有給申請システムの利⽤</label>
        <div class="col-sm-1">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" v-model="formData.paid_leave">
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">タイムカード調整を⾏う</label>
        <div class="col-sm-1">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" v-model="formData.time_adjustment">
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-success btn-block" @click="dialog">登録</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-default btn-block" @click="this.$router.push({name:'CompanyStaffPage'});">キャンセル</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ formData.name }}を登録します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-success" @click="register">登録する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      formData: {
        name: null,
        email: null,
        password: null,
        shop: [],
        regular_working_hours_start: null,
        regular_working_hours_stop: null,
        break_time: null,
        hourly_wage: null,
        remaining_paid_leave_days: null,
        age: null,
        gender: null,
        phone_text: null,
        postal_code: null,
        address: null,
        date_of_joining: null,
        time_adjustment: false,
        paid_leave: false,
      },
      shops: [],
    }
  },
  methods: {
    validateNumber(event) {
      const key = event.key;
      if (!/[0-9]/.test(key)) {
          event.preventDefault();
      }
    },
    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2({
        language: {
          noResults: function() {
            return "まずはスタッフ⼊⼒を⾏ってください"; 
          }
        }
      });

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
    },
    dialog() {
      $('#modal-default').modal();
    },
    dialogClose() {
      $('#modal-default').modal('hide');
    },
    edit() {
      this.$router.push({name:'CompanyStaffEditPage'});
    },
    register () {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&:*-])[A-Za-z\d#?!@$%^&:*-]{8,16}$/;
      if (!passwordRegex.test(this.formData.password)) {
        this.$toast.error('英⼤⽂字、⼩⽂字、記号（#?!@$%^&*-）、数字を各１以上含む８⽂字以上で作成してください');
      } else {
        this.formData.shop = $('#shops').val();
        this.formData.company_id = this.$store.getters.company;
        this.$http.post(process.env.VUE_APP_API_PATH + '/company/staff', this.formData)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            setTimeout(() => {
              this.$router.push({ name: 'CompanyStaffPage' });
            }, 1000);
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.data
            if (error.response.status === 422) {
              if (response.errors.email) {
                this.$toast.error(response.errors.email[0]);
                return false;
              }
              this.$toast.error('未入力の必須項目があります');
            } else {
              this.$toast.error(response.message);
            }
          }
        });
      }
      this.dialogClose();
    },
    getShopList () {
      const company_id = this.$store.getters.company;
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shopList/'+company_id)
      .then(response => {
        if (response.data.success) {
          this.shops = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
  },
  mounted() {
    this.initializeSelect2();
    this.getShopList();
  },
};
</script>

<style scoped>
  .required {
    font-size: 18px;
    color: #ff0000;
  }
  
  input[type="checkbox"] {
    scale: 1.7;
  }
</style>