<template>
  <div class="__timecard">
    <LeaveLogo class="mb-5" title="有給申請"></LeaveLogo>
    <div class="__date">{{ this.data.date.format('YYYY/MM/DD') }}</div>
    <div class="d-flex flex-column align-items-center">
      <button 
        class="btn __outline rounded __punch __punched-no mt-5"
        @click="showTimeEditDialog('start')"
      >
        <div class="__btn-text">有給申請</div>
        <span class="__punch-time align-items-center">
          <div class="__time d-flex align-items-center"><span style="font-size: 30px;">1</span>日</div>
        </span>
      </button>
      <button 
        class="btn __outline rounded __punch __punched-no mt-4"
        @click="showTimeEditDialog('start')"
      >
        <div class="__btn-text">申請可能日数</div>
        <span class="__punch-time align-items-center">
          <div class="__time d-flex align-items-center"><span style="font-size: 30px;" class="text-success">{{ this.data?.remaining_paid_leave_days }}</span>日</div>
        </span>
      </button>
      <button class="btn __primary __btn-save rounded mb-2" @click="applyLeave">
        申請する
      </button>
    </div>
  </div>
  <div class="modal fade" id="message" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content" style="border-radius: 20px;">
        <div class="modal-body d-flex flex-column align-items-center">
          <div style="margin-right: auto;">{{ message }}</div>
          <div style="margin-left: auto;">
            <button class="btn" style="font-weight: bolder;" @click="goBack">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeaveLogo from '../../components/LeaveLogo.vue';
import $ from 'jquery'

export default {
  data () {
    return {
      data: null,
      message: ''
    }
  },
  components: { LeaveLogo },
  beforeMount() {
    try {
      const data = JSON.parse(decodeURIComponent(this.$route.params.data));  
      const requiredProperties = [ 'date', 'paid_leave', 'remaining_paid_leave_days' ];
        const hasAllProperties = requiredProperties.every(prop => Object.hasOwn(data, prop));
        if (hasAllProperties && data.date.match(/^\d{4}-\d{2}-\d{2}$/)) {
          this.data = data;
          this.data.date = this.$moment(data.date);
        } else {
          this.$router.push({ name: 'List'});
        }
    } catch (error) {
      this.$router.push({ name: 'List'});
    }
  },
  mounted() {
    if (this.data.remaining_paid_leave_days <= 0) {
      this.message = '有給休暇がありません。';
      $('#message').modal();
    }
  },
  methods: {
    goBack() {
      $('#message').modal('hide');
      this.$router.go(-1);
    },
    async applyLeave() {
      if (!this.data.paid_leave) {

        return;
      }
      try {
        const response = await this.$http.post(process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard/paid-leave`, {
          date: this.data.date.format('YYYY-MM-DD')
        });
        if (response.data.success) {
          this.message = '有給休暇を申請しました';
          $('#message').modal();
        }
      } catch (error) {
        console.log(error);
        
      }
    }
  }
}
</script>
<style scoped lang="scss">
.__date {
  font-size: 24px;
  font-weight: 500;
}

.__outline {
  height: 65px !important;
}

.__btn-save {
  margin-top: 80px;
  width: 200px;
}
</style>