import store from '../store/store';
import Cookies from 'js-cookie';

export function checkAndSetAuthFromCookie() {
  const cookieValue = Cookies.get('token');
  const permissionValue = Cookies.get('access');
  const userName = Cookies.get('userName');
  const company = Cookies.get('company');
  const shop = Cookies.get('store');
  const shop_name = Cookies.get('store_name');
  const data = {
    data: {
      token: cookieValue, 
      access: parseInt(permissionValue),
      userName: userName,
      company_id:company,
      shop_id:shop,
      shop_name:shop_name
    }
  }

  if (cookieValue) {
    store.dispatch('setAuth', data);
  } else {
    Cookies.remove("token");
    Cookies.remove("permission");
    Cookies.remove("userName");
    Cookies.remove("company");
    Cookies.remove("store");
    Cookies.remove("store_name");
  }
}
