<template>
  <nav class="menu-navigation">
    <ul>
      <li>
        <router-link to="/timecard"><i class="fa fa-edit"></i> 入力</router-link>
      </li>
      <li>
        <router-link to="/timecard/list"><i class="fa fa-calendar"></i> 一覧</router-link>
      </li>
      <li>
        <router-link to="/timecard/application"><i class="fa fa-check"></i> 申請</router-link>
      </li>
      <li>
        <router-link to="/timecard/user-info"><i class="fa fa-cogs"></i> 設定</router-link>
      </li>
      <!-- Add more menu items as needed -->
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MenuNavigation",
};
</script>

<style scoped>
.menu-navigation {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.menu-navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-navigation li {
  margin-bottom: 10px; /* Space between menu items */
}

.menu-navigation a {
  text-decoration: none;
  color: #000;
  padding: 10px 20px;
  display: block;
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border-radius: 5px; /* Rounded corners */
}

.menu-navigation a:hover {
  background-color: #45a049; /* Darker green on hover */
}
</style>
