<template>
  <div class="__timecard">
    <ListLogo class="__logo-margin" title="承認一覧"></ListLogo>
    <div class="row m-0 justify-content-between align-items-center __width __date_picker">
      <div class="row justify-content-center w-100 m-0">
        <div>
          <select v-model="selectedYear" class="">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
          <label for="" class="ml-1">年</label>
        </div>
        <div class="ml-3">
          <select v-model="selectedMonth" class="">
            <option v-for="(month, index) in months" :key="index" :value="month">
              {{ month }}
            </option>
          </select>
          <label for="" class="ml-1">月</label>
        </div>
      </div>
      <button class="__icon" @click="isCalendarDisplay = !isCalendarDisplay">
        <!-- Calendar days icon -->
        <svg v-if="!isCalendarDisplay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#fff">
          <path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/>
        </svg>
        <!-- List icon -->
        <svg v-if="isCalendarDisplay" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#fff">
          <path d="M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l448 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24l224 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-224 0c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24l224 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-224 0c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24l224 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-224 0c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
        </svg>
      </button>
    </div>
    <div class="mt-4">
      <TimecardCalendar :is-calendarDisplay="isCalendarDisplay" :is-application="true" :year="selectedYear" :month="selectedMonth - 1"></TimecardCalendar>
    </div>
  </div>
</template>
<script>
import ListLogo from '../../components/ListLogo.vue';
import TimecardCalendar from '../../components/TimecardCalendar.vue';

export default {
  data () {
    return {
      isCalendarDisplay: false,
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      selectedYear: this.$moment().year(),
      selectedMonth: this.$moment().month() + 1,
    }
  }, 
  components: { ListLogo, TimecardCalendar },
  beforeMount() {    
    const currentYear = this.$moment().year();
    for (let i = 0; i <= 10; i++) {
      this.years.push(currentYear - i);
    }
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
.__width {
  width: 520px;
}

.__date_picker {
  position: relative;
}

.__icon {
  position: absolute;
  z-index: 10;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: #049C4C;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  padding: 8px;
  cursor: pointer;
}
</style>