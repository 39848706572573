<template>
  <div class="__login __timecard">
    <img class="__head" :src="loginHead" alt="">
    <form class="__form" @submit.prevent="login">
      <div class="row __field">
        <div class="__label">ID</div>
        <input v-model="id" class="">
      </div>
      <div class="row __field mt-3">
        <div class="__label">パスワード</div>
        <div class="__append-icon">
          <input v-model="password" class="" :type="showPassword ? 'text' : 'password'">
          <div class="__icon" @click="togglePasswordVisibility">
            <div v-if="showPassword"><span class="reset-confirm-eye fas fa-eye-slash"></span></div>
            <div v-if="!showPassword"><span class="reset-confirm-eye fas fa-eye"></span></div>
          </div>
        </div>
      </div>
      <div class="row float-right __check">
        <input v-model="rememberMe" type="checkbox" class="mr-2">
        <label class="form-check-label remember" for="remember">ログイン情報を保存する</label>
      </div>
      <div class="row mt-5 justify-content-center">
        <button class="btn __primary rounded __btn-login">
          <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>ログイン</span>
        </button>
      </div>
      <p class="mt-2 text-center">
        <a class="__forgot-link" href="https://dev1.softnext.co.jp/minnanokintai?redirect=mobile">パスワードを忘れた方はこちら</a>
      </p>
    </form>
  </div>
  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6" v-if="shops">
              <h5 class="mb-2">ログイン店舗を選択</h5>
              <div class="form-check mb-3" v-for="(shop) in shops" :key="shop.id">
                <button class="btn" @click="selectShop(shop)">{{ shop.shop_name }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginHeadImg from '../../assets/img/timecard/login-head.png';
import $ from 'jquery'

export default {
  data () {
    return {
      loginHead: loginHeadImg,
      showPassword: false,
      id: null,
      password: null,
      rememberMe: false,
      isLoading: false,
      data: null,
      shops: [],
      company: [],
      selectedCompany: null,
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.$http.post(process.env.VUE_APP_API_PATH + '/auth/login', {
        email: this.id,
        password: this.password,
        remember: this.remember,
        is_mobile: true,
      })
      .then(response => {
        this.data = response.data;
        this.shops = response.data.data.shops;
        $('#modal-default').modal();
        this.isLoading = false
      })
      .catch(error => {
          if (error.response) {
            const response = error.response.data;
            if (response.errors) {
              this.$toast.error("Error: "+response.errors.email[0]);
            } else {
              this.$toast.error(response.message);
            }
          }
          this.isLoading = false;
      });
    },
    async selectShop(shop) {
      this.data.data.shop_id = shop.id;
      this.data.data.shop_name = shop.shop_name;
      await this.$store.dispatch('setAuth', this.data);
      $('#modal-default').modal('hide');
      this.$router.push({ name: 'PunchTime' });
    }
  }
}
</script>

<style scoped lang="scss">
.__login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.__head {
  height: 20vh;
  margin-bottom: 100px;
}

.__label {
  width: 100px;
  text-align: right;
  margin-right: 30px;
}

.__field {
  align-items: center;
  input {
    width: 250px;
  }
}

.__append-icon {
  position: relative;

  input {
    padding-right: 35px !important;
  }

  .__icon {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 10px;
    cursor: pointer;
  } 
}

.__check {
  align-items: center;
}

.__btn-login{
  width: 200px;
  margin-top: 50px;
}

.__forgot-link {
  color: #049C4C;
  text-decoration: underline;
  text-decoration-color: #000;
}
</style>