<template>
  <div class="__diagonal-bg">
    <div class="container">
      <MenuNavigation v-if="showMenu" class="menu-nav" />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import MenuNavigation from "../../components/MenuNavigation.vue";

export default {
  components: {
    MenuNavigation,
  },
  computed: {
    showMenu() {
      // Check if the current route path is not the login page
      return this.$route.path !== '/timecard/login';
    },
  },
  mounted() {
    // Log the current path to the console
    console.log('Current path:', this.$route.path);
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  align-items: flex-start;
  padding: 20px; /* Adjust padding around container */
}

.menu-nav {
  margin-top: 200px; /* Space from top */
  margin-right: -220px; /* Space between menu and content */
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative; /* Ensure it is positioned correctly */
}

.content {
  flex-grow: 1; /* Take up remaining space */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  min-height: 100vh; /* Ensure content takes full height */
}
</style>
