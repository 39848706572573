<template>
  <div class="">
    <div v-if="isCalendarDisplay">
      <div class="__header">
        <div v-for="(day, key) in days" :key="key" class="__item">
          {{ day }}
        </div>
      </div>
      <div v-for="(week, weekKey) in weeks" :key="weekKey" class="__header">
        <div
          v-for="(item, dayKey) in week"
          :key="dayKey"
          class="__item"
          :style="{ color: item.color }"
          @click="dateClick(item)"
        >
          <div>{{ item.day }}</div>
          <div v-if="item.dataFlag === 1" class="__time" :style="{ color: item.textColor }">
            {{ item.timecard && item.timecard.time_start ? $moment(item.timecard.time_start).format('HH:mm') : '' }}
          </div>
          <div v-if="item.dataFlag === 1" class="__time" :style="{ color: item.textColor }">
            {{ getTimeOut(item.timecard) }}
          </div>
          <div v-if="item.dataFlag === 2" class="__paid-leave" :style="{ color: item.textColor }">
            {{ approvalStatus[item.paidLeave.approval_status] }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="__width">
      <table class="__list">
        <thead>
          <tr>
            <th>日付</th>
            <th>出勤</th>
            <th>退勤</th>
            <th>休憩</th>
            <th>就業時間</th>
            <th>ステータス</th>
          </tr>
        </thead>
        <tbody class="__width">
          <tr v-for="(item, dayKey) in isApplication ? applicationDaylist : daysList" :key="dayKey">
            <td @click="editTimecard(item)" :class="{'__pointer': isApplication}">
              {{ item.day }} ({{ getDay(item.day) }})
            </td>
            <td @click="editTimecard(item)"
              :style="{ color: item.dataFlag === 2 ? '#000' : item.textColor }"
              :class="{'__pointer': isApplication}"
            >
              <div v-if="item.dataFlag === 1">
                {{ item.timecard && item.timecard.time_start ? $moment(item.timecard.time_start).format('HH:mm') : '' }}
              </div>
              <div v-if="item.dataFlag === 2">
                {{ $moment(`${$moment().format('YYYY-MM-DD')} ${staff.regular_working_hours_start}`).format('HH:mm') }}
              </div>
            </td>
            <td @click="editTimecard(item)"
              :style="{ color: item.dataFlag === 2 ? '#000' : item.textColor }"
              :class="{'__pointer': isApplication}"
            >
              <div v-if="item.dataFlag === 1">
                {{ item.timecard && item.timecard.time_stop ? $moment(item.timecard.time_stop).format('HH:mm') : '--:--' }}
              </div>
              <div v-if="item.dataFlag === 2">
                {{ $moment(`${$moment().format('YYYY-MM-DD')} ${staff.regular_working_hours_stop}`).format('HH:mm') }}
              </div>
            </td>
            <td @click="editTimecard(item)"
              :style="{ color: item.dataFlag === 2 ? '#000' : item.textColor }"
              :class="{'__pointer': isApplication}"
            >
              <div v-if="item.dataFlag === 1">
                {{ item.timecard && item.timecard.basic_break_hours ? item.timecard.basic_break_hours.slice(0, -3) : '' }}
              </div>
              <div v-if="item.dataFlag === 2">
                {{ $moment(`${$moment().format('YYYY-MM-DD')} ${staff.break_time}:00`).format('HH:mm') }}
              </div>
            </td>
            <td
              @click="editTimecard(item)"
              :style="{ color: item.dataFlag === 2 ? '#000' : item.textColor }"
              :class="{'__pointer': isApplication}"
            >
            <span v-if="item.dataFlag !== 2">
              {{ item.timecard && item.timecard.working_hours && item.timecard.working_hours !== '00:00' ? item.timecard.working_hours : '--:--' }}
            </span>
              <span v-else>有給申請</span>
            </td>
            <td :style="{ color: item.textColor }" :class="{'__pointer': isApplication}" @click="showComment(item)">
              <div v-if="item.dataFlag === 1">{{ approvalStatus[item.timecard?.approval_status] }}</div>
              <div v-if="item.dataFlag === 2">{{ approvalStatus[item.paidLeave?.approval_status] }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal fade" id="comment-edit">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content __comment">
        <div class="modal-body d-flex flex-column align-items-center">
          <textarea v-model="selectedItem.notes" rows="5"></textarea>
          <button class="btn btn-note rounded mt-4 mb-2 w-100" @click="saveNotes">
            メッセージを送信
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="paidLeave-edit">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content __comment">
        <div class="modal-body d-flex flex-column align-items-center">
          <p>有給申請をキャンセルします。よろしいですか︖</p>
          <div class="modal-footer justify-content-between  ">
          <button type="button" class="btn btn-danger mr-5" data-dismiss="modal">いいえ</button>
          <button type="button" class="btn btn-primary ml-5"  @click="removePaidLeave">はい</button>
        </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="date-entry-type">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content __comment">
        <div class="modal-body d-flex flex-column align-items-center">
          <button class="btn btn-danger rounded mt-4 mb-2 w-100" @click="dateEntryTypeClick(0)">
            時間入力を編集
          </button>
          <button v-if="hasPaidLeave" class="btn btn-primary rounded mt-4 mb-2 w-100"  @click="dateEntryTypeClick(1)">
            有給休暇申請 
          </button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import $ from 'jquery'

export default {
  data () {
    return {
      days: ['月', '火', '水', '木', '金', '土', '日'],
      weeks: [],
      daysList: [],
      applicationDaylist: [],
      paidLeaves: [],
      staff: null,
      timecards: [],
      approvalStatus: { 1: '承認待ち', 2: '承認済み', 3: '修正依頼' },
      selectedItem: {
        notes: null
      },
      paidLeaveId: null,
      dateEntryTypeItem: {},
      selectedDate: null,
    }
  },
  props: ['isCalendarDisplay', 'isApplication', 'year', 'month', 'hasPaidLeave'],
  beforeMount() {    
    this.generateCalendar();
  },
  watch: {
    year() {
      this.generateCalendar();
    },
    month() {
      this.generateCalendar();
    }
  },
  methods: {
    async generateCalendar() {
      await this.fetchData();      
      const daysInMonth = this.$moment([this.year, this.month]).daysInMonth();
      const daysInPrevMonth = this.$moment([this.year, this.month - 1]).daysInMonth();
      const firstDayOfWeek = (this.$moment([this.year, this.month, 1]).day() - 1 + 7) % 7;
      const daysBeforeMonth = Array.from({ length: firstDayOfWeek }, (_, i) => { return { day: daysInPrevMonth - firstDayOfWeek + i + 1, color: '#b9b7b7' } });
      const daysInCurrentMonth = Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1;
        const dayMoment = this.$moment([this.year, this.month, day]);
        const isSunday = dayMoment.day() === 0;
        const timecard = this.timecards.find(item => this.$moment(this.$moment(item.time_start).format('YYYY-MM-DD')).isSame(dayMoment));
        let paidLeave = undefined;
        if (timecard === undefined) {
          paidLeave = this.paidLeaves.find(item => this.$moment(this.$moment(item.paid_leave_date).format('YYYY-MM-DD')).isSame(dayMoment))
        }
        // dataFlag = 0 -> no data, = 1 -> timecard data, = 2 -> paid leave data
        const dataFlag = timecard === undefined && paidLeave === undefined ? 0 : timecard !== undefined ? 1 : 2;
        // Time text color
        const approvalStatus = dataFlag === 1 ? timecard.approval_status : dataFlag === 2 ? paidLeave.approval_status : -1;
        let textColor = '#000';
        switch (approvalStatus) {
          case 1:
            textColor = '#BFB820';
            break;
          case 2:
            textColor = '#049C4C';
            break;
          case 3:
            textColor = 'red';
            break;
        }
        return { day, color: isSunday ? '#f44444' : 'black', timecard: timecard, paidLeave: paidLeave, dataFlag: dataFlag, textColor: textColor };
      });      
      const daysAfterMonth = (Math.ceil((firstDayOfWeek + daysInMonth) / 7) * 7) - (firstDayOfWeek + daysInMonth);
      const daysInNextMonth = Array.from({ length: daysAfterMonth }, (_, i) => { return { day: i + 1, color: '#b9b7b7' } });
      const daysList = [...daysBeforeMonth, ...daysInCurrentMonth, ...daysInNextMonth];
      
      // Group days into weeks (7 days per week)
      const weeks = [];
      for (let i = 0; i < daysList.length; i += 7) {
        weeks.push(daysList.slice(i, i + 7));
      }
      this.daysList = daysInCurrentMonth;
      this.applicationDaylist = daysInCurrentMonth.filter(item => item.dataFlag !== 0);      
      this.weeks = weeks;
    },
    showComment(item) {      
      this.selectedItem = item.dataFlag === 1 ? item.timecard : item.paidLeave;
      this.selectedItem.dataFlag = item.dataFlag;
      $('#comment-edit').modal();
    },  
    editTimecard(item) {      
      if (this.isApplication) {
        this.dateClick(item);
      }
    },
    getTimeOut(timecard) {      
      const timePlaceholder = timecard?.time_start !== undefined ? '--:--' : '';
      return timecard ? timecard?.time_stop !== null ? this.$moment(timecard?.time_stop).format('HH:mm') :  timePlaceholder : timePlaceholder;
    },
    getDay(dayNum) {
      const day = this.$moment([this.year, this.month, dayNum]).day();      
      return ['日', '月', '火', '水', '木', '金', '土'][day];      
    },
    dateClick(item) {      
      switch (item.dataFlag) {
        case 0:
            if (this.$moment(`${this.year}-${(this.month+1).toString().padStart(2, '0')}-${item.day.toString().padStart(2, '0')}`).isBefore(this.$moment(), 'day')) {
              this.dateEntryTypeItem = item;
              this.selectedDate = this.$moment(`${this.year}-${(this.month+1).toString().padStart(2, '0')}-${item.day.toString().padStart(2, '0')}`);
              $('#date-entry-type').modal();
              return;
            }
          if ( this.hasPaidLeave ) {
            this.$router.push({ 
              name: 'LeaveApplication',
              params: { 
                data: encodeURIComponent(JSON.stringify({
                  date: `${this.year}-${(this.month+1).toString().padStart(2, '0')}-${item.day.toString().padStart(2, '0')}`,
                  remaining_paid_leave_days: this.staff.remaining_paid_leave_days,
                  paid_leave: this.staff.paid_leave
                })),
              },
            });
          }
          break;
        case 1:
          item.timecard.regular_working_hours_start = this.staff.regular_working_hours_start;
          item.timecard.regular_working_hours_stop = this.staff.regular_working_hours_stop;
          console.log(item.timecard);
          
          this.$router.push({ name: 'StampCorrection', params: { timecard: encodeURIComponent(JSON.stringify(item.timecard)) } });
          break;
        case 2:
          if (item.paidLeave.approval_status !== 3) return;
          this.paidLeaveId = item.paidLeave.id;
          $('#paidLeave-edit').modal();
          break;
      }      
    },
    dateEntryTypeClick(type){
      if (!this.dateEntryTypeItem) {
        this.dateEntryTypeItem = {};
      }
      if (!this.dateEntryTypeItem['timecard']) {
        this.dateEntryTypeItem['timecard'] = {};
      }
      
      this.dateEntryTypeItem['timecard']['date'] = this.selectedDate;
      this.dateEntryTypeItem['timecard']['id'] = 0;
      this.dateEntryTypeItem['timecard']['staff_id'] = this.staff.id;
      this.dateEntryTypeItem['timecard']['working_hours'] = this.staff.regular_working_hours;
      this.dateEntryTypeItem['timecard']['regular_working_hours_start'] = this.staff.regular_working_hours_start;
      this.dateEntryTypeItem['timecard']['regular_working_hours_stop'] = this.staff.regular_working_hours_stop;
      this.dateEntryTypeItem['timecard']['basic_break_hours'] = `${this.staff.break_time.toString().padStart(2, '0')}:00`;

      $('#date-entry-type').modal('hide');
      if (type === 1 && this.hasPaidLeave) {
        this.$router.push({ 
            name: 'LeaveApplication',
            params: { 
              data: encodeURIComponent(JSON.stringify({
                date: `${this.year}-${(this.month+1).toString().padStart(2, '0')}-${this.dateEntryTypeItem.day.toString().padStart(2, '0')}`,
                remaining_paid_leave_days: this.staff.remaining_paid_leave_days,
                paid_leave: this.staff.paid_leave
              })),
            },
          });
          return;
      } 
        this.$router.push({ name: 'StampCorrection', params: { timecard: encodeURIComponent(JSON.stringify(this.dateEntryTypeItem.timecard)) } });
      
    },
    async saveNotes() {      
      try {
        $('#comment-edit').modal('hide');
        const response = await this.$http.post(process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard/notes`, {
          timecard_id: this.selectedItem.dataFlag === 1 ? this.selectedItem.id : null,
          paid_leave_id: this.selectedItem.dataFlag === 2 ? this.selectedItem.id : null,
          notes: this.selectedItem.notes,
        });        
        if (response.data.success) {
          this.$toast.success('ノートが更新されました');
        }        
      } catch (error) {
        console.log(error);
        this.$toast.error('メモの更新に失敗しました');
      }
    },
    async fetchData() {
      try {
        const response = await this.$http.get(process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard?year=${this.year}&month=${this.month + 1}`)
        if (response.data.success) {
          this.paidLeaves = response.data.data.paid_leaves;
          this.staff = response.data.data.staff;
          this.timecards = response.data.data.timecards;          
        }
      } catch (error) {
        console.log(error);
      }      
    },
    async removePaidLeave() {
      try {
        $('#paidLeave-edit').modal('hide');
        const response = await this.$http.delete(process.env.VUE_APP_API_PATH + `/staff/shop/${this.$store.getters.shop}/timecard/paid-leave/${this.paidLeaveId}`)
        if (response.data.success) {
          this.$toast.success('ノートが更新されました');
          this.generateCalendar();
        } 
      } catch (error) {
        console.log(error);
      } 
    },
  }
}
</script>
<style scoped lang="scss">
.__width {
  width: 530px;
}
.__header {
  display: flex;
  width: 530px;
  height: 60px;
  border-bottom: 1px solid #000;

  .__item {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
    padding-top: 5px;
    cursor: pointer;

    .__time {
      font-size: 10px;
      font-weight:500
    }

    .__paid-leave {
      font-size: 10px;
      font-weight: 600;
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
}
.__list {
  width: 100%; 
  table-layout: fixed;

  th, td {
    text-align: center;
  }

  thead tr {
    padding-left: 5px !important;
    background-color: #fff;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    height: 45px;
    font-size: 14px;
  }

  tbody {
    display: block;
    height: 400px; 
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;

    tr { 
      border-bottom: 1px solid grey;
      height: 45px;
      font-size: 14px;

      td {
        width: calc(530px / 6);
      }
    }
  }

  tbody::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.__pointer {
  cursor: pointer;
}

.__comment {
  border-radius: 25px;
  
  textarea {
    border-radius: 10px;
    border: 2px solid grey;
    width: 100%;
    padding: 5px 10px;
  }
}

.btn-note {
  background-color: #781CdC;
  color: #fff;

  &:hover {
    color: #fff;
  }
}
</style>